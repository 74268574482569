import ApplicationController from '.';

export default class extends ApplicationController<HTMLElement> {
  static targets: string[] = ['source'];

  declare readonly sourceTargets: HTMLElement[];

  connect() {
    super.connect();
    this.currentSourceIndex = 0;
  }

  get currentSource() {
    return this.sourceTargets.find((target) =>
      target.hasAttribute('aria-current'),
    );
  }

  set currentSource(target: HTMLElement | undefined) {
    if (target) {
      this.sourceTargets.forEach((item) =>
        item.removeAttribute('aria-current'),
      );

      target.setAttribute('aria-current', 'true');
    }
  }

  get currentSourceIndex() {
    if (this.currentSource) {
      return this.sourceTargets.indexOf(this.currentSource);
    }

    return -1;
  }

  set currentSourceIndex(index: number) {
    this.currentSource = this.sourceTargets[index];
    this.element.dataset.currentIndex = index.toString();
  }

  next() {
    this.currentSourceIndex += 1;
  }

  previous() {
    this.currentSourceIndex -= 1;
  }
}
