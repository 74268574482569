import ApplicationController from '.';
import { EventWithParams } from '../@types/stimulus';

type ListToggleEvent = EventWithParams<{
  index: number;
}>;

export default class extends ApplicationController {
  static targets: string[] = ['image'];

  declare readonly imageTargets: HTMLPictureElement[];

  private isCurrent(image: HTMLPictureElement) {
    return image.hasAttribute('aria-current');
  }

  private getImageAtIndex(index: number) {
    return this.imageTargets.find((image) => {
      const { listToggleIndexParam: targetIndex } = image.dataset;

      return targetIndex && index === Number.parseInt(targetIndex, 10);
    });
  }

  private showImage(index: number) {
    const targetImage = this.getImageAtIndex(index);

    if (targetImage && !this.isCurrent(targetImage)) {
      this.dismissImages();

      if (targetImage.parentElement) {
        targetImage.parentElement.appendChild(targetImage);
      }

      this.later(() => {
        targetImage.setAttribute('aria-current', 'true');
      }, 100);
    }
  }

  private dismissImages() {
    this.imageTargets.forEach((image) => {
      image.removeAttribute('aria-current');
    });
  }

  select(event: ListToggleEvent) {
    this.showImage(event.params.index);
  }

  dismiss() {
    this.dismissImages();
  }
}
