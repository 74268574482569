import { interpolate, Interpolator } from 'flubber';
import gsap from 'gsap';
import type { ValueDefinitionMap } from '@hotwired/stimulus/dist/types/core/value_properties';

import ApplicationController from '.';
import { useMemo } from 'stimulus-use';

export type MorphState = 'start' | 'end';

export default class extends ApplicationController<SVGElement> {
  static memos = ['totalPathLength'];

  static targets: string[] = ['path', 'stroke'];

  declare readonly pathTarget?: SVGPathElement;
  declare readonly hasPathTarget: boolean;

  declare readonly strokeTarget?: SVGPathElement;
  declare readonly hasStrokeTarget: boolean;

  static values: ValueDefinitionMap = {
    toPath: String,
    animationDuration: { type: Number, default: 2 },
    animationDelay: { type: Number, default: 0 },
    strokeTotalLength: { type: Number, default: 0 },
  };

  declare toPathValue: string;
  declare animationDurationValue: number;
  declare animationDelayValue: number;
  declare strokeTotalLengthValue: number;

  private fromPath?: string;
  private interpolator?: Interpolator;

  private morphState: MorphState = 'start';

  private currentTimeline?: gsap.core.Timeline;
  private progress = { t: 0 };

  connect(): void {
    super.connect();
    useMemo(this);

    this.later(() => {
      this.initFromPath();
      this.initStrokeLength();

      if (this.fromPath) {
        this.initInterpolators();
      }
    });
  }

  private initFromPath() {
    if (this.hasPathTarget && this.pathTarget) {
      this.fromPath = this.pathTarget.getAttribute('d') || undefined;
    }
  }

  private initStrokeLength() {
    if (this.hasStrokeTarget && this.strokeTarget) {
      this.strokeTarget.setAttribute(
        'stroke-dasharray',
        this.strokeTotalLengthValue.toString(),
      );
    }
  }

  private initInterpolators() {
    if (this.fromPath) {
      this.interpolator = interpolate(this.fromPath, this.toPathValue);
    }
  }

  private async animateTo(state: MorphState) {
    if (this.currentTimeline) {
      await this.currentTimeline.then();
    }

    if (
      this.morphState !== state &&
      this.interpolator &&
      this.hasPathTarget &&
      this.pathTarget
    ) {
      this.currentTimeline = gsap.timeline({
        onUpdate: () => {
          if (this.hasStrokeTarget && this.strokeTarget) {
            const length = this.strokeTotalLengthValue;

            this.strokeTarget.setAttribute(
              'stroke-dashoffset',
              (length - length * this.progress.t).toString(),
            );
          }

          if (this.interpolator && this.hasPathTarget && this.pathTarget) {
            this.pathTarget.setAttribute(
              'd',
              this.interpolator(this.progress.t),
            );
          }
        },

        onComplete: () => {
          this.morphState = state;
          delete this.currentTimeline;
        },
      });

      this.currentTimeline.add(
        gsap.to(this.progress, {
          t: state === 'start' ? 0 : 1,

          duration: this.animationDurationValue,
          delay: this.animationDelayValue,
          ease: 'power4.inOut',
        }),
      );

      this.currentTimeline.play();
    }
  }

  async animateToStart() {
    await this.animateTo('start');
  }

  async animateToEnd() {
    await this.animateTo('end');
  }
}
