import { ValueDefinitionMap } from '@hotwired/stimulus/dist/types/core/value_properties';
import ApplicationController from '.';

export default class extends ApplicationController<HTMLElement> {
  static targets: string[] = ['panel'];

  declare readonly panelTarget?: HTMLElement;
  declare readonly hasPanelTarget: boolean;

  static values: ValueDefinitionMap = {
    mode: { type: String, default: 'left' },
    offset: Number,
  };

  declare modeValue: 'left' | 'right';

  declare offsetValue?: number;
  declare readonly hasOffsetValue: boolean;

  connect(): void {
    super.connect();

    this.bind(window, 'resize', () => this.applyOffset());
    this.applyOffset();
  }

  get effectiveOffset(): number {
    if (this.hasPanelTarget && this.panelTarget) {
      const { left, width } = this.element.getBoundingClientRect();
      const { innerWidth } = window;

      if (this.modeValue === 'left') {
        return -left + (this.offsetValue || 0);
      } else {
        return innerWidth - (left + width) - (this.offsetValue || 0);
      }
    }

    return 0;
  }

  private applyOffset() {
    if (this.hasPanelTarget && this.panelTarget) {
      this.panelTarget.style.setProperty(
        this.modeValue,
        `${this.effectiveOffset}px`,
      );
    }
  }
}
