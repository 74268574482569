import { interpolate } from 'flubber';
import gsap from 'gsap';

import SimplePaginationController from './simple-pagination-controller';

type TweenMap = Record<string, gsap.core.Tween>;

export default class extends SimplePaginationController {
  static targets: string[] = ['source', 'svg', 'image', 'path'];

  declare readonly sourceTargets: HTMLElement[];

  declare readonly svgTarget?: SVGElement;
  declare readonly hasSvgTarget: boolean;

  declare readonly imageTargets: SVGImageElement[];

  declare readonly pathTargets: SVGPathElement[];

  private currentTween: TweenMap = {};

  private morphPaths(source: HTMLElement) {
    if (this.hasSvgTarget && this.svgTarget) {
      const sourceClass = this.svgTarget.getAttribute('class');
      const { color: targetColor } = source.dataset;

      if (sourceClass && targetColor) {
        this.svgTarget.setAttribute(
          'class',
          sourceClass.replace(/text-[\w-]+/, targetColor),
        );
      }

      this.pathTargets.forEach((target) => {
        const { pathId: id } = target.dataset;

        if (id) {
          const targetPathData = source.dataset[`${id}Path`];
          const sourcePathData = target.getAttribute('d');

          if (sourcePathData && targetPathData) {
            const progress = { t: 0 };
            const interpolator = interpolate(sourcePathData, targetPathData);

            if (id in this.currentTween) {
              this.currentTween[id].kill();
            }

            const tween = gsap.to(progress, {
              t: 1,

              duration: 1,
              ease: 'expo.inOut',

              onUpdate() {
                target.setAttribute('d', interpolator(progress.t));
              },

              onComplete: () => {
                delete this.currentTween[id];
              },
            });

            this.currentTween[id] = tween;
          }
        }
      });

      this.imageTargets.forEach((image) => {
        const { pageIndex } = image.dataset;

        if (pageIndex) {
          if (Number.parseInt(pageIndex, 10) === this.currentSourceIndex) {
            if (image.parentElement) {
              image.parentElement.appendChild(image);
            }

            this.later(() => {
              image.setAttribute('aria-current', 'true');
            });
          } else {
            image.removeAttribute('aria-current');
          }
        }
      });
    }
  }

  get currentSource() {
    return super.currentSource;
  }

  set currentSource(target: HTMLElement | undefined) {
    if (target) {
      super.currentSource = target;

      this.later(() => {
        this.morphPaths(target);
      });
    }
  }
}
