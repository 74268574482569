import { useIntersection } from 'stimulus-use';
import ApplicationController from '.';
import { ValueDefinitionMap } from '@hotwired/stimulus/dist/types/core/value_properties';

// Type not exported from library 😑
interface IntersectionOptions extends IntersectionObserverInit {
  element?: Element;
  dispatchEvent?: boolean;
  eventPrefix?: boolean | string;
  visibleAttribute?: string;
}

export default class extends ApplicationController {
  static values: ValueDefinitionMap = {
    rootMargin: { type: String, default: '0px 0px 0px 0px' },
    threshold: { type: Number, default: 0.0 },
  };

  declare rootMarginValue: string;
  declare thresholdValue: number;

  connect(): void {
    super.connect();

    useIntersection(this, this.options);
  }

  get options(): IntersectionOptions {
    return {
      eventPrefix: 'intersection',
      rootMargin: this.rootMarginValue,
      threshold: this.thresholdValue,
    };
  }
}
