import SimplePagination from './simple-pagination-controller';

export default class extends SimplePagination {
  static targets: string[] = ['source', 'image'];

  declare readonly sourceTargets: HTMLElement[];
  declare readonly imageTargets: SVGImageElement[];

  protected syncImages() {
    this.imageTargets.forEach((image) => {
      const { pageIndex } = image.dataset;

      if (pageIndex) {
        if (Number.parseInt(pageIndex, 10) === this.currentSourceIndex) {
          if (image.parentElement) {
            image.parentElement.appendChild(image);
          }

          this.later(() => {
            image.setAttribute('aria-current', 'true');
          });
        } else {
          image.removeAttribute('aria-current');
        }
      }
    });
  }

  get currentSource() {
    return super.currentSource;
  }

  set currentSource(target: HTMLElement | undefined) {
    if (target) {
      super.currentSource = target;
      this.syncImages();
    }
  }
}
