import Bowser from 'bowser';

import ApplicationController from '.';

export default class extends ApplicationController<HTMLElement> {
  connect(): void {
    super.connect();
    this.initBowser();
  }

  private initBowser(): void {
    const browser = Bowser.getParser(window.navigator.userAgent);

    this.data.set('name', browser.getBrowserName() || '(unknown)');
    this.data.set('version', browser.getBrowserVersion() || '(unknown)');
    this.data.set('os-name', browser.getOSName() || '(unknown)');
    this.data.set('os-version', browser.getOSVersion() || '(unknown)');
    this.data.set('engine', browser.getEngineName() || '(unknown)');

    if ('ontouchstart' in window) {
      this.data.set('isTouchDevice', '');
    }
  }
}
